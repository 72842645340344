document.addEventListener('DOMContentLoaded', async () => {
  const baseUrl = 'https://us-central1-swipetube-3c5c6.cloudfunctions.net/cms-stripePayments';
  // const baseUrl = 'http://localhost:5000/swipetube-3c5c6/us-central1/cms-stripePayments';
  const stripe = Stripe('pk_live_fs3aQh0sUBDcTFFFxMVuhrkQ00YnKedaLI', {
  // const stripe = Stripe('pk_test_sKEKsc1jTKDUDgNGCxDKb9Fc00xgYcsguA', {
    apiVersion: '2020-08-27',
  });
  const items = [{id: 'xl-tshirt'}];

  let paymentMethodId;
  let elements;

  function setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector('#submit').disabled = true;
      document.querySelector('#spinner').classList.remove('hidden');
      document.querySelector('#button-text').classList.add('hidden');
    } else {
      document.querySelector('#submit').disabled = false;
      document.querySelector('#spinner').classList.add('hidden');
      document.querySelector('#button-text').classList.remove('hidden');
    }
  }

  async function initialize() {
    const response = await fetch(baseUrl + '/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ items }),
    });
    const { clientSecret, id } = await response.json();
    const appearance = { theme: 'stripe' };

    paymentMethodId = id;
    elements = stripe.elements({ appearance, clientSecret });

    const paymentElement = elements.create('payment', {
      fields: {
        billingDetails: { email: 'never' },
      },
    });
    paymentElement.mount('#payment-element');
  }

  function showMessage(messageText) {
    const messageContainer = document.querySelector('#payment-message');
    messageContainer.classList.remove('hidden');
    messageContainer.textContent = messageText;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const email = document.getElementById('email').value;

    try {
      await fetch(baseUrl + '/update-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: paymentMethodId, email }),
      });
    } catch (e) {
      console.error(e);
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://secure.vidtao.com/success.html',
        payment_method_data: {
          billing_details: { email },
        },
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      showMessage(error.message);
    } else {
      showMessage('An unexpected error occured.');
    }

    setLoading(false);
  }

  async function checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case 'succeeded':
        showMessage('Payment succeeded!');
        break;
      case 'processing':
        showMessage('Your payment is processing.');
        break;
      case 'requires_payment_method':
        showMessage('Your payment was not successful, please try again.');
        break;
      default:
        showMessage('Something went wrong.');
        break;
    }
  }

  await initialize();
  await checkStatus();

  document
    .querySelector('#payment-form')
    .addEventListener('submit', handleSubmit);
});
